<template>
  <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4">
    <d-grid-card-wrapper>
      <q-card-section class="q-pb-none">
        <div class="grid-item__row">
          <div class="grid-item__title">Customer Transaction Id</div>
          <div class="grid-item__value">
            <q-btn
              class="action"
              color="primary"
              size="sm"
              :disable="!item.row.editCustomerTransactionId"
              :outline="!item.row.editCustomerTransactionId"
              :label="item.row.customerTransactionId"
              @click="
                $emit('on-customer-transaction-id', {
                  id: item.row.id,
                  customerTransactionId: item.row.customerTransactionId,
                })
              "
            />
            <d-copy-btn
              class="q-ml-sm"
              :value="item.row.customerTransactionId"
            />
          </div>
        </div>
        <div class="grid-item__row">
          <div class="grid-item__title">Merchant transaction Id</div>
          <div class="grid-item__value">
            {{ item.row.merchantTransactionId }}
          </div>
        </div>
        <div class="grid-item__row">
          <div class="grid-item__title">Amount</div>
          <div class="grid-item__value">
            <template v-if="checkIsDisableAmountChange(item.row.status)">
              {{ item.colsMap.amount.format(item.row.amount) }}
            </template>
            <template v-else>
              <q-badge
                class="cursor-pointer"
                @click="$emit('on-amount', item.row.amount)"
              >
                {{ item.colsMap.amount.format(item.row.amount) }}
              </q-badge>
            </template>
          </div>
        </div>
        <div class="grid-item__row">
          <div class="grid-item__title">Status</div>
          <div class="grid-item__value">
            <q-select
              :disable="statusSelectDisable"
              dense
              borderless
              stack-label
              :value="item.row.status"
              @input="$emit('on-status', $event)"
              :options="statusDictionaryFn(item.row.status)"
            >
              <template v-slot:selected>
                <q-chip
                  dense
                  square
                  v-bind="statusChipProps"
                  class="q-my-xs q-ml-xs q-mr-none"
                />
              </template>
            </q-select>
          </div>
        </div>
      </q-card-section>
      <q-slide-transition>
        <div v-show="expanded">
          <q-card-section class="text-subitle2 q-pt-none">
            <div v-if="item.row.operator" class="grid-item__row">
              <div class="grid-item__title">Changed by</div>
              <div class="grid-item__value">
                {{ item.row.operator.username }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Merchant name</div>
              <div class="grid-item__value">
                {{ item.row.merchant.name }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Customer name</div>
              <div class="grid-item__value">
                {{ item.row.customerName }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Customer wallet id</div>
              <div class="grid-item__value">
                {{ item.row.customerWalletId }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Currency</div>
              <div class="grid-item__value">
                {{ item.row.currency }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Payment value</div>
              <div class="grid-item__value">
                <template v-if="item.row.paymentRequisite">
                  <div
                    v-for="(val, key) in item.row.paymentRequisite"
                    :key="key"
                  >
                    <span class="text-weight-bold q-mr-xs"> {{ key }}: </span>
                    <span>
                      {{ val }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div>None</div>
                </template>
              </div>
            </div>
            <div class="grid-item__row">
              <d-object-view
                v-if="Object.keys(item.row.payload).length"
                label="Payload"
                :value="item.row.payload"
              />
              <template v-else>
                <div>None</div>
              </template>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Requisite</div>
              <div class="grid-item__value">
                <d-requisite-btn
                  dense
                  size="sm"
                  :class="{ 'full-width': !isDisabledAgentSwitchByUserRole }"
                  :as-text="isDisabledAgentSwitchByUserRole"
                  :is-disable="isDisabledAgentSwitchByUserRole"
                  @click="
                    requisiteHandler(
                      item.row.paymentRequisite.refillMethodTypeId,
                      item.row.id
                    )
                  "
                >
                  {{ item.row.paymentRequisite.name }} <br />
                  {{ item.row.agentName }}
                </d-requisite-btn>
                <d-requisite-select
                  v-if="currentRefillOrderId === item.row.id"
                  :value="item.row"
                  :options="currentRequisites"
                  @input="$emit('on-update-requisite', $event)"
                />
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Created at</div>
              <div class="grid-item__value">
                {{ item.row.createdAt }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Updated at</div>
              <div class="grid-item__value">
                {{ item.row.updatedAt }}
              </div>
            </div>
          </q-card-section>
        </div>
      </q-slide-transition>
      <q-btn
        flat
        :icon="expanded ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'"
        class="full-width"
        @click="expanded = !expanded"
      />
    </d-grid-card-wrapper>
  </div>
</template>

<script>
import DRequisiteBtn from './requisite-btn';
import DRequisiteSelect from './requisite-select';
import withAgentSwitchDisable from './withAgentSwitchDisable';
import withAmountChangeDisable from './withAmountChangeDisable';
import withRequisiteLoader from './withRequisiteLoader';
import { DCopyBtn } from '@/features/copy-btn';
import { DGridCardWrapper } from '@/features/grid-card-wrapper';
import { DObjectView } from '@/features/object-view';
import { STATUSES } from '@/shared/constants';

export default {
  mixins: [
    withRequisiteLoader,
    withAgentSwitchDisable,
    withAmountChangeDisable,
  ],
  components: {
    DObjectView,
    DGridCardWrapper,
    DRequisiteSelect,
    DRequisiteBtn,
    DCopyBtn,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    statusSelectDisable: {
      type: Boolean,
      default: () => false,
    },
    statusChipProps: {
      type: Object,
      required: true,
    },
    statusDictionaryFn: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    expanded: false,
    statuses: Object.freeze(STATUSES),
  }),
};
</script>
