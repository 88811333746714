import { formatAmount } from '@/shared/utils';

export const tableColumns = [
  {
    name: 'details',
    field: 'details',
    label: 'Details',
    isHide: false,
    align: 'left',
  },
  {
    name: 'customer-transaction-id',
    field: 'customerTransactionId',
    label: 'Customer Transaction Id',
    isHide: false,
    align: 'left',
  },
  {
    name: 'merchant-transaction-id',
    field: 'merchantTransactionId',
    label: 'Merchant transaction Id',
    isHide: false,
    align: 'left',
  },
  {
    name: 'amount',
    field: 'amount',
    label: 'Amount',
    format: (val) => formatAmount(Math.trunc(+val)),
    isHide: false,
    align: 'left',
  },
  {
    name: 'status',
    field: 'status',
    label: 'Status',
    isHide: false,
    align: 'left',
  },
  {
    name: 'merchant',
    field: (row) => row.merchant.name,
    label: 'Merchant name',
    isHide: true,
  },
  {
    name: 'payment-requisite-value',
    field: (row) => row.paymentRequisite.payload,
    label: 'Payment value',
    isHide: true,
  },
  {
    name: 'merchant-user-id',
    field: 'merchantUserId',
    label: 'Merchant user ID',
    isHide: true,
  },
  {
    name: 'requisite',
    field: 'requisite',
    label: 'Requisite',
    isHide: true,
  },
  {
    name: 'operator',
    field: (row) => row?.user?.username,
    label: 'Operator',
    isHide: true,
  },
  {
    name: 'currency',
    field: 'currency',
    label: 'Currency',
    isHide: true,
  },
  {
    name: 'customer-wallet-id',
    field: 'customerWalletId',
    label: 'Customer wallet Id',
    isHide: true,
  },
  {
    name: 'customer-name',
    field: 'customerName',
    label: 'Customer name',
    isHide: true,
  },
  {
    name: 'payload',
    field: 'payload',
    label: 'Payload',
    isHide: true,
  },
  {
    name: 'created-at',
    field: (row) => row?.createdAt,
    label: 'Created at',
    isHide: true,
  },
  {
    name: 'updated-at',
    field: (row) => row?.updatedAt,
    label: 'Updated at',
    isHide: true,
  },
];
