<template>
  <q-select
    dense
    label="Select requisite"
    :value="value.paymentRequisite"
    emit-value
    option-value="id"
    @input="
      $emit('input', {
        paymentRequisiteId: $event,
        id: value.id,
      })
    "
    :options="options"
  >
    <template v-slot:selected>
      <q-item dense>
        <q-item-section>
          <q-item-label>{{ value.paymentRequisite.name }}</q-item-label>
          <q-item-label caption>{{
            value.agentName || 'No agent'
          }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section avatar>
          <q-avatar
            :color="scope.opt.active ? 'green' : 'red'"
            text-color="white"
            :icon="scope.opt.active ? 'mdi-account' : 'mdi-account-lock'"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ scope.opt.name }}</q-item-label>
          <q-item-label caption>{{ scope.opt.agentName }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
