import { ROLES, STATUSES } from '@/shared/constants';
import { authUtils } from '@/shared/utils';

export default {
  computed: {
    isSupportOfficer: () =>
      authUtils.getRoleByHierarchy() === ROLES.ROLE_SUPPORT_OFFICER,
    isAdmin: () => authUtils.getRoleByHierarchy() === ROLES.ROLE_ADMIN,
  },
  methods: {
    checkIsDisableAmountChange(status) {
      if (this.isSupportOfficer) {
        return true;
      }

      const includeStatuses = [STATUSES.PENDING];

      if (this.isAdmin) {
        includeStatuses.push(STATUSES.DECLINED);
      }

      return !includeStatuses.includes(status);
    },
  },
};
