<template>
  <div class="row">
    <q-input
      class="col-grow"
      type="number"
      filled
      dense
      placeholder="Amount from"
      v-model="before"
      clearable
    />
    <q-input
      class="col-grow"
      type="number"
      filled
      dense
      placeholder="Amount to"
      v-model="after"
      clearable
      :rules="[notLessFrom]"
      hide-bottom-space
      lazy-rules
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data: (vm) => ({
    localRange: vm.value,
  }),
  computed: {
    before: {
      get() {
        return this.localRange[0];
      },
      set(value) {
        this.$set(this.localRange, 0, value === null ? value : +value);
        this.$emit('input', this.localRange);
      },
    },
    after: {
      get() {
        return this.localRange[1];
      },
      set(value) {
        this.$set(this.localRange, 1, value === null ? value : +value);
        if (value === null || this.notLessFrom(this.localRange[1])) {
          this.$emit('input', this.localRange);
        }
      },
    },
  },
  methods: {
    notLessFrom(val) {
      if (val === null) return true;
      else return val > this.localRange[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  gap: 8px !important;
}
</style>
