<template>
  <q-dialog ref="dialog" @hide="onDialogHide" persistent>
    <q-card class="q-dialog-plugin">
      <q-form ref="form" @submit="onOKClick">
        <q-card-section class="text-h6"> Change amount </q-card-section>
        <q-card-section>
          <div class="text-subtitle2">Enter amount value'</div>
          <q-input
            ref="input"
            type="number"
            v-model.number="localValue"
            :rules="rules"
          />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="primary" label="OK" type="submit" />
          <q-btn color="primary" label="Cancel" @click="onCancelClick" />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data: (vm) => ({
    localValue: Number(vm.value),
  }),

  methods: {
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit('hide');
    },

    onOKClick() {
      this.$emit('ok', this.localValue);
      this.hide();
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>
