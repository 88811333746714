<template>
  <div class="row">
    <q-input
      label="Count"
      debounce="500"
      dense
      filled
      class="col-grow"
      v-model="amount"
      clearable
    />
    <q-select
      clearable
      label="Type"
      :options="options"
      dense
      filled
      v-model="type"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: (vm) => ({
    localValue: { ...vm.value },
    options: [
      { value: 'eq', label: '=' },
      { value: 'lte', label: '<=' },
      { value: 'lt', label: '<' },
      { value: 'gte', label: '>=' },
      { value: 'gt', label: '>' },
    ],
  }),
  computed: {
    type: {
      get() {
        return this.localValue.type;
      },
      set(type) {
        this.$set(this.localValue, 'type', type);
        this.$emit('input', this.localValue);
      },
    },
    amount: {
      get() {
        return this.localValue.value;
      },
      set(value) {
        this.$set(this.localValue, 'value', value);
        this.$emit('input', this.localValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  gap: 4px;

  &:last-child {
    flex: 1 1 1rem;
  }
}
</style>
