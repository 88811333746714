export const DEFAULT_FILTERS_STATE = {
  status: [],
  merchantId: null,
  paymentRequisiteId: null,
  merchantTransactionId: null,
  merchantUserId: null,
  currency: null,
  userId: null,
  customerTransactionId: null,
  customerWalletId: '',
  customerName: null,
  payload: null,
  agentId: null,
  refillMethodTypeId: null,
  createdAt: {
    from: '',
    to: '',
  },
  amount: {
    from: null,
    to: null,
  },
  userRefillCount: {
    value: null,
    type: null,
  },
};
