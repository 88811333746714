<template>
  <q-btn
    v-bind="$attrs"
    color="primary"
    :disable="isDisable"
    :outline="isOutline"
    :flat="asText"
    @click="$emit('click')"
  >
    <slot />
  </q-btn>
</template>

<script>
export default {
  props: {
    isDisable: {
      type: Boolean,
      default: () => false,
    },
    isOutline: {
      type: Boolean,
      default: () => false,
    },
    asText: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
