<template>
  <d-virtual-table
    ref="refillOrderTable"
    :grid="grid"
    :columns="columns"
    :items="items"
    with-pagination
    is-body-slot
    :expanded.sync="expanded"
    @on-pagination="$emit('on-pagination', $event)"
  >
    <template v-slot:grid-item="{ props }">
      <d-grid-card
        :status-chip-props="chipStatusConfigBuilder(props.row.status)"
        :item="props"
        :status-select-disable="checkIsDisable(props.row.status)"
        :status-dictionary-fn="getStatusesByCurrentStatus"
        @on-status="$emit('on-status', $event, props.row.id)"
        @on-amount="
          $emit('on-amount', {
            id: props.row.id,
            amount: $event,
            minLimit: props.row.minLimit,
            maxLimit: props.row.maxLimit,
          })
        "
        @on-customer-transaction-id="
          $emit('on-customer-transaction-id', $event)
        "
        @on-update-requisite="$emit('on-update-requisite', $event)"
      />
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <template v-for="col in props.cols">
          <q-td
            v-if="col.name === 'details'"
            auto-width
            :props="props"
            :key="col.name"
          >
            <q-btn
              size="sm"
              color="accent"
              round
              dense
              @click="props.expandRow(props.key)"
              :icon="props.expand ? 'mdi-chevron-down' : 'mdi-chevron-up'"
            />
          </q-td>
          <q-td
            v-else-if="col.name === 'customer-transaction-id'"
            auto-width
            :props="props"
            :key="col.name"
          >
            <q-btn
              color="primary"
              size="sm"
              no-caps
              :disable="!props.row.editCustomerTransactionId"
              :outline="!props.row.editCustomerTransactionId"
              :label="col.value"
              @click="
                $emit('on-customer-transaction-id', {
                  id: props.row.id,
                  customerTransactionId: col.value,
                })
              "
            />
            <d-copy-btn class="q-ml-sm" :value="col.value" />
          </q-td>
          <q-td
            v-else-if="col.name === 'amount'"
            :key="col.name"
            :props="props"
          >
            <q-btn
              color="primary"
              :label="col.format(props.row.amount)"
              :disable="checkIsDisableAmountChange(props.row.status)"
              :outline="checkIsDisableAmountChange(props.row.status)"
              size="sm"
              class="amount-btn"
              @click="
                $emit('on-amount', {
                  id: props.row.id,
                  amount: props.row.amount,
                  minLimit: props.row.minLimit,
                  maxLimit: props.row.maxLimit,
                })
              "
            />
          </q-td>
          <q-td
            v-else-if="col.name === 'status'"
            :key="col.name"
            :props="props"
          >
            <q-select
              :disable="checkIsDisable(props.row.status)"
              dense
              outlined
              stack-label
              :value="props.row.status"
              @input="$emit('on-status', $event, props.row.id)"
              :options="getStatusesByCurrentStatus(props.row.status)"
            >
              <template v-slot:selected>
                <q-chip
                  dense
                  square
                  v-bind="chipStatusConfigBuilder(props.row.status)"
                  class="q-my-xs q-ml-xs q-mr-none"
                />
              </template>
            </q-select>
          </q-td>
          <q-td v-else :key="col.name" :props="props">
            {{ col.value }}
          </q-td>
        </template>
      </q-tr>
      <q-tr v-if="props.expand" :props="props">
        <q-td colspan="100%">
          <d-expanded-area
            @on-update-requisite="$emit('on-update-requisite', $event)"
            :value="props"
          />
        </q-td>
      </q-tr>
    </template>
  </d-virtual-table>
</template>

<script>
import { tableColumns } from './config';
import { DExpandedArea, DGridCard, withRequisiteLoader } from './features';
import withAmountChangeDisable from './features/withAmountChangeDisable';
import { DCopyBtn } from '@/features/copy-btn';
import { ORDER_TYPES, ROLES, STATUSES } from '@/shared/constants';
import { withStatusDisabling } from '@/shared/mixins/withStatusDisabling';
import DVirtualTable from '@/shared/ui/virtual-table';
import { authUtils, copyToBuffer } from '@/shared/utils';

export default {
  components: {
    DVirtualTable,
    DGridCard,
    DExpandedArea,
    DCopyBtn,
  },
  mixins: [
    withStatusDisabling(ORDER_TYPES.REFILLS),
    withRequisiteLoader,
    withAmountChangeDisable,
  ],
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    chipStatusConfigBuilder: {
      type: Function,
      required: true,
    },
  },
  methods: {
    copyToBuffer,
  },
  data: () => ({
    expanded: [],
    statuses: Object.freeze(STATUSES),
  }),
  computed: {
    columns() {
      const filteredCols = authUtils.checkRoles([ROLES.ROLE_AGENT])
        ? tableColumns
        : tableColumns.filter((el) => el.name !== 'operator');

      return filteredCols.filter((col) => !col.isHide);
    },
  },
};
</script>

<style scoped lang="scss">
.virtual-scroll-table {
  height: 49em;
  .amount-btn {
    min-width: 4rem;
  }
}
</style>
