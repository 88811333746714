import { ROLES } from '@/shared/constants';
import { authUtils } from '@/shared/utils';

export default {
  computed: {
    isDisabledAgentSwitchByUserRole() {
        return !authUtils.checkRoles([ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN, ROLES.ROLE_SUPPORT_OFFICER]);
    },
  },
};
