import { paymentRequisitesController } from '@/shared/api';
import { authUtils, notify } from '@/shared/utils';

export default {
  data: () => ({
    currentRequisites: [],
    currentRefillOrderId: null,
    paymentRequisiteAPI: Object.freeze(
      paymentRequisitesController(authUtils.getRoleByHierarchy())
    ),
  }),
  methods: {
    async requisiteHandler(refillMethodTypes, refillOrderId) {
      if (this.currentRefillOrderId === refillOrderId) {
        this.currentRefillOrderId = null;
      } else {
        await this.fetchRequisites(refillMethodTypes, refillOrderId);
      }
    },
    async fetchRequisites(refillMethodTypes, refillOrderId) {
      try {
        const { data } =
          await this.paymentRequisiteAPI.getPaymentRequisitesWithFilters({
            filter: {
              refillMethodTypeId: refillMethodTypes.map(rmt => rmt.id),
            },
          });

        this.currentRequisites = data;
        this.currentRefillOrderId = refillOrderId;
      } catch (e) {
        notify.error();
      }
    },
  },
};
