<template>
  <q-btn
    v-bind="$attrs"
    flat
    dense
    size="sm"
    icon="mdi-content-copy"
    @click="copyData"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    copyData() {
      navigator.clipboard.writeText(this.value);
    },
  },
};
</script>
